import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FAQs from "../components/pricing/faqs"
import Plans from "../components/pricing/plans"
import CTA from "../components/pricing/cta";
// import ScrollingTestimonials from "../components/home/testimonialsScroll";
import FamewallEmbed from 'react-famewall'
import Header from '../components/blog/header';


const PricingPage = (props) => {

  return (
    <Layout
      title="Pricing" subtitle={'Affordable audience research at your fingertips'}
      includeBanner={false} lightHeader={false}
      seoComponent={<SEO title="Pricing" path={props.path} description="Affordable unlimited subscription plan that helps you accelerate your business effortlessly every single day." />}
    >
      <div className="content-body markdown-body">
        <Header title="Pricing" />
      </div>
      
      <Plans />
      {/*<FAQs />*/}
      
      <div className="mb-20">
        {/*<ScrollingTestimonials />*/}
        <FamewallEmbed wallUrl={'gummysearch-value'} />
      </div>

      <CTA />
    </Layout>
  )
}


export default PricingPage;
